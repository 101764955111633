import { Verified } from '@lux/ds/Icon/MaterialIcons';
import { LayoutJay } from '@lux-ds/content-layouts';
import { css, styled } from '@@utils/styles';

export const StyledLayoutJay = styled(LayoutJay)`
  flex: 1;
  justify-content: space-between;
`;

export const VerifiedIcon = styled(Verified)(
  ({ theme }) => css`
    align-self: center;
    color: ${theme.palette.success.dark};
    height: 16px;
    margin-left: -24px;
  `,
);

export default {
  auditLogs: {
    actualDeliveryDate: 'Actual Delivery Date',
    auditLogs: 'Audit Logs',
    authenticationPartner: 'Authentication Partner',
    authenticationPhotos: 'Authentication Photos',
    authenticationStatus: 'Authentication Status',
    brand: 'Brand',
    businessModel: 'Business Model',
    carat: 'Carat',
    category: 'Category',
    code: 'Code',
    color1: 'Color 1',
    color2: 'Color 2',
    colors: 'Colors',
    costUSD: 'Cost USD',
    countryOfOrigin: 'Country of Origin',
    courier: 'Courier',
    damages: 'Damages',
    deliveryDate: 'Delivery Date',
    description: 'Description',
    editedPhotos: 'Edited Squared Photos - 2000px x 2000px',
    editedVerticalPhotos: 'Edited Rectangle Photos - 2048px x 2732px',
    editor: 'Editor',
    finalBsp: 'Final BSP',
    gender: 'Gender',
    highCompetitorPrice: 'High Competitor Price',
    inclusions: 'Inclusions',
    inventoryStatus: 'Inventory Status',
    itemMeasures: 'Item Measures',
    itemTags: 'Item Tags',
    lowCompetitorPrice: 'Low Competitor Price',
    materials: 'Materials',
    midCompetitorPrice: 'Mid Competitor Price',
    modelName: 'Model Name',
    modelNumber: 'Model Number',
    name: 'Name',
    name1: 'Name of Material 1',
    name2: 'Name of Material 2',
    productId: 'Product ID',
    productTags: 'Product Tags',
    purchaseOrder: 'Purchase Order',
    qcDecision: 'QC Decision',
    rating: 'Rating',
    rawPhotosUploadDateTimeUtc: 'Upload Date',
    retailPrice: 'Retail Price USD',
    reviewOptionSelected: 'Item Checkup',
    sellerCode: 'Seller Code',
    serialNumber: 'Serial Number',
    shade1: 'Shade 1',
    shade2: 'Shade 2',
    size: 'Size',
    sku: 'SKU',
    status: 'Status',
    subCategory: 'Sub Category',
    supplierRating: 'Supplier Rating',
    supplierReturnReasons: 'Supplier Return Reasons',
    trackingNumbers: 'Tracking Numbers',
    type1: 'Type of Material 1',
    type2: 'Type of Material 2',
    value: 'Value',
    yearManufactured: 'Year Manufactured',
    reviewAttributesToBeRectified: 'Attributes To Be Rectified',
    reviewAttributesToBeRectifiedComment: 'Attributes To Be Rectified Comment',

    headers: {
      action: 'Action',
      module: 'Module',
      timestamp: 'Timestamp',
      user: 'User',
    },
    module: {
      inventory: 'Inventory',
      purchaseOrders: 'Purchase Orders',
    },

    /** ERRORS */
    errorGetLogs: 'There was an error getting audit logs!',
    errorGetMovements: 'There was an error getting item movements!',
  },
  common: {
    allItemTags: 'All Item Tags',
    brand: 'Brand',
    browseFiles: 'Browse Files',
    cancel: 'Cancel',
    category: 'Category',
    certified: 'Certified',
    comments: 'Comments',
    competitiveness: 'Competitiveness',
    costUSD: 'Cost USD',
    downloadTemplate: 'Download Template',
    edit: 'Edit',
    finalBSP: 'Final BSP',
    headerTitle: 'Items',
    highCompetitorPrice: 'High Competitor Price',
    inclusions: 'Inclusions',
    item: 'Item',
    itemBusinessModel: 'Item Business Model',
    itemSuggestedBSP: 'Item suggested BSP',
    itemSuggestedRRP: 'Item suggested RRP',
    itemTags: 'Item Tags',
    itemWithIssues: 'With Issues',
    katalog: 'Katalog',
    kiloBytes: '{{size}} KB',
    lowCompetitorPrice: 'Low Competitor Price',
    margin: 'Margin',
    midCompetitorPrice: 'Mid Competitor Price',
    modelName: 'Model Name',
    neverMind: 'Never Mind',
    or: 'or',
    photos: 'Photos',
    poBusinessModel: 'PO Business Model',
    profit: 'Profit',
    purchaseOrder: 'Purchase Order',
    qualityCheck: 'Quality Check',
    rating: 'Rating',
    reason: 'Reason',
    reasons: 'Reasons',
    reproductMatch: 'Re-Product Match',
    rereview: 'Re-Review',
    retailPriceUSD: 'Retail Price USD',
    save: 'Save',
    saving: 'Saving...',
    search: 'Search',
    seeMore: 'See More',
    sellerCode: 'Seller Code',
    sku: 'SKU',
    subCategory: 'Sub Category',
    submitting: 'Submitting...',
    supplier: 'Supplier',
    update: 'Update',
    vlgPriority: 'VLG Priority',

    uploadFiles: {
      dragDropToUpload: 'Drag & drop files to upload',
      supportedDefaultFiles: 'Supported Files: XLS, XLSX or CSV',
    },

    /** Errors */
    errorInvalidForm: 'Please complete the missing required fields.',
  },
  itemDetails: {
    /** Common */
    cancelChanges: 'Cancel changes',
    cm: 'cm',
    color: 'Color',
    damageRecognition: 'Damage Recognition',
    damages: 'Damages',
    description: 'Description',
    details: 'Details',
    discardChanges: 'Discard Changes',
    gender: 'Gender',
    goBack: 'Go back',
    history: 'History',
    leave: 'Leave',
    measures: 'Measures',
    modelNumber: 'Model Number',
    nameOfMaterial: 'Name of Material',
    none: 'None',
    productTags: 'Product Tags',
    saveSubmit: 'Save and submit',
    shade: 'Shade',
    size: 'Size',
    submit: 'Submit',
    typeOfMaterial: 'Type of Material',

    /** Errors */
    errorGetItem: 'There was an error getting the item details!',
    errorGetItemMaterialNames: 'There was an error getting the item material names!',
    errorGetItemShades: 'There was an error getting the item shades!',
    errorGetMatchingProducts: 'There was an error getting the matching products!',
    errorGetMaterialNames: 'There was an error getting the material names!',
    errorGetOptions: 'There was an error getting the item details options!',
    errorGetPhotos: 'There was an error getting the item photos!',
    errorGetRatings: 'There was an error getting the ratings!',
    errorGetShades: 'There was an error getting the shades!',

    /** Discard Changes Dialog */
    discardAllChanges: 'Cancel changes?',
    discardChangesDescription: "You'll lose any unsaved changes if you cancel without saving.",
    discardChangesSuccess: 'Changes were canceled.',

    /** Submit Dialog */
    createNewProduct: 'Save and create new Product',
    createNewProductDescription:
      "You didn't find a corresponding Product ID. Are you sure you want\nto save and create a new Product?",
    createNewProductRestrictedDescription:
      "You don't have permissions to create a new product. Please save changes, paste the SKU to an Excel and talk to your Lead Buyer.",
    itemDetailsCompleted: 'Item Details Completed',
    itemDetailsCompletedDescription:
      'You have successfully matched a Product ID to the item and filled in the rest of the Item\ninformation. Are you sure you want to submit?',
    itemDetailsCompletedDescriptionReview:
      'You have successfully matched a Product ID to this item. Are you sure you want to save it?',
    itemDetailsCompletedReview: 'Product ID Matched',
    itemMatched: 'Item matched with a Product ID',
    missingProductId: 'Save and submit to new product flow?',
    nonSeniorCreateProductDescription:
      'Product ID is required to continue. Please go back and find a\nProduct ID, or save changes and submit to new product flow.',
    nonSeniorCreateProductMessage: 'You don’t have permissions to create a new product.',
    nonSeniorSendBackToPM: 'Item %SKU% was sent to new product flow.',
    saveCreate: 'Save and create',
    submitChanges: 'Changes were saved.',
    submitCreate: 'Submit and create',
    submitCreateNewProduct: 'Submit and create new Product',
    submitCreateNewProductDescription:
      "You didn't find a corresponding Product ID. Are you sure you\nwant to submit and create a new Product?",
    submitError: 'There was an error submitting the item details!',
    submitItemDetails: 'Item Details were successfully saved.',
    submitPricingInfo: 'Pricing Info were successfully saved.',
    submitProductDetails: 'Product Details were saved.',
    submitSuccess: 'Item %SKU% completed successfully.',
    submitSuccessDescription: 'This item has been submitted.',
    submitSuccessProductCreated: 'Item %SKU% saved and created new Product',

    /** Change Category Dialog */
    changeCategoryTitle: 'Change Category?',
    changeCategoryDescription:
      'To correspond to the selected category, this action may impact the\ninformation presented in the Product and Item Details, such as:',
    changeCategorySuccess: 'The category has been updated.',
    changeCategoryReviewStatus:
      'Please try to find the Product ID, fill in the mandatory fields,\nsave the changes, and you will return to the Review view.',

    /** Lost Product Match Dialog */
    lostProductMatchTitle: 'Loss of Product ID Match',
    lostProductMatchDescription:
      'You are changing an attribute from Product Match.\nAre you sure you want to change it and lose the Product ID Match?',

    /** Lost Product ID Dialog for Item Review */
    lostProductIDTitle: 'Change attribute and lose Product ID?',
    lostProductIDDescription:
      'Changing this product attribute will result in losing the Product\nID Match and opening the product match sidebar to find a new\nmatch.',

    /** Unsaved Changes Dialog */
    continueToItemsList: 'Continue to Items List',
    continueToReview: 'Continue to Review',
    quitEditing: 'Quit editing and save changes?',
    quitEditingDescription:
      'You have unsaved changes. Are you sure you want to quit\nediting and save the changes?',
    quitEditingSave: 'Quit and save',
    leavePage: 'Leave item page?',
    leavePageDescription: "You'll lose any unsaved changes if you leave the page.",
    saveChanges: 'Save changes',
    unsavedChanges: 'Unsaved Changes',
    unsavedChangesDescription:
      'You have unsaved changes. Are you sure you want to continue\nand lose this information?',
    unsavedChangesLeavingDescription: 'Are you sure you want to leave without saving the changes?',

    /** Main Detail */
    changeMainPhoto: 'Change Main Photo',
    supplierItemLink: 'Supplier Item Link',

    /** Photo Gallery Dialog */
    imageCount: 'Image %CURRENT_IMAGE% of %IMAGE_COUNT%',

    /** Select Photo Dialog */
    confirm: 'Confirm',
    selectPhotoError: 'There was an error changing the Main Photo!',
    selectPhotoDescription: 'Select the photo from the front, to help match the item.',
    selectPhotoTitle: 'Change main photo to Front Photo.',
    selectPhotoSuccess: 'Main Photo successfully changed.',

    /** Product Details */
    change: 'Change',
    changeAttribute: 'Change attribute',
    changeCategory: 'Change category',
    infoTooltipItemReview:
      'Editing the description or measures of a matched item will not affect the preservation of its Product ID.',
    infoTooltipFirstPoint:
      'The filled item measurements are preserved, regardless of those provided by the ProductID.',
    infoTooltipSecondPoint:
      "If measurements field are left blank, they are automatically filled with the product's measurements, while non-required measurements remain empty.",
    infoTooltipTitle: 'Expected behaviour for Measures when matching an item to a ProductID:',
    productDetails: 'Product Details',
    productId: 'Product ID',
    productIdSuggestion: 'Product ID Suggestion',

    /** Item Details */
    carat: 'Carat / ct',
    countryOfOrigin: 'Country of Origin',
    itemDetails: 'Item Details',
    serialNumber: 'Serial Number',
    yearManufactured: 'Year Manufactured',

    /** Item Tags */
    addTags: 'Add Tags',

    /** Pricing Info */
    finalBSPChanged: 'Final BSP changed',
    pricingInfo: 'Pricing Info',

    /** Product Match */
    findProductMatch: 'Find Product Match',
    match: 'Match',
    matching: 'Matching...',
    noProductMatchesFound: 'No products found',
    productIdWarning: 'Item lost the Product ID previously matched.',
    productMatchInvalidSuggestedId: 'The Product ID suggestion is invalid.',
    productMatchRequiredMessage:
      'Please fill in Sub Category, Brand, Color and Name of Material to Find Product Match.',

    /** Product Card */
    accurate: 'Accurate',
    moreInfo: 'More Info',
    matchedProductInfo: 'Product ID was matched to this item.',
    matchedProductInfoDescription: 'Continue filling in the item details below.',
    productInfoApplied: 'Product ID and Info applied to the item',
    productMatchError: 'There was an error matching a product!',
    suggested: 'Suggested',

    /** Product More Info Dialog */
    moreInfoDescription:
      'After selecting this Product ID, the information below will be filled in the Item Details.',
    moreInfoTitle: 'Product ID %PRODUCT_ID% Info',
    moreInfoError: 'There was an error getting the product info!',

    /** Channels */
    channels: 'Channels',
  },
  itemList: {
    /** Common */
    createNew: 'Create new',

    /** Errors */
    errorGetFilters: 'There was an error getting the filters options!',
    errorGetItemTags: 'There was an error getting the item tags!',
    errorGetItems: 'There was an error getting the items!',
    errorGetMeasures: 'There was an error getting the measures!',

    /** Radio Buttons */
    /** Aggregator */
    allItems: 'All Items',
    delivered: 'Delivered', // TODO: remove
    disposedOf: 'Disposed Of', // TODO: remove
    draft: 'Draft',
    inTransit: 'In Transit', // TODO: remove
    marketplace: 'Marketplace', // TODO: remove
    onHold: 'On Hold',
    ordered: 'Ordered',
    outOf: 'Out Of',
    pendingPricing: 'Pending Pricing', // TODO: remove
    pendingProcessing: 'Pending Processing', // TODO: remove
    pendingReview: 'Pending Review', // TODO: remove
    processed: 'Processed',
    processing: 'Processing',
    published: 'Published',
    reviewed: 'Reviewed', // TODO: remove
    sold: 'Sold',
    soldBySupplier: 'Sold By Supplier', // TODO: remove
    unpublished: 'Unpublished', // TODO: remove

    /** Item Published Status */
    available: 'Available',
    consigned: 'Consigned',
    reserved: 'Reserved',

    /** Item Out Of Status */
    canceled: 'Cancelled',
    supplierReturn: 'Supplier Return',

    /** Pipeline Status */
    awaiting: 'Awaiting',
    pricing: 'Pricing',
    productMatch: 'Product Match',
    review: 'Review',

    /** DataGrid */
    days: '%DAYS% day(s)',
    searchPlaceholder: 'Search for SKUs or PO-number',

    /** DataGrid Filters */
    acquiredDate: 'Acquired Date',
    warehouse: 'Warehouse',

    /** Change Item Tags Popover */
    addTags: 'Add tags',
    changeItemTagsAddSuccess: 'Selected tags added to the item(s).',
    changeItemTagsError: 'There was an error changing the item(s) tags!',
    changeItemTagsRemoveSuccess: 'Selected tags removed from the item(s).',
    removeTags: 'Remove tags',

    /** Create Item Tag Dialog */
    createItemTagDescription: 'Please enter the new item tag.',
    createItemTagError: 'This item tag already exists.',
    createItemTagTitle: 'Create item tag',
    itemTag: 'Item tag',

    /** Change Item Business Model Dialog */
    changeItemBusinessModelDescription:
      'Do you really want to change %COUNT% Item(s) Business Model?',
    changeItemBusinessModelError: 'There was an error changing the Item(s) Business Model!',
    changeItemBusinessModelSuccess: 'Item(s) Business Model successfully changed.',
    changeItemBusinessModelTitle: 'Change Item Business Model',
    changeItems: 'Change Item(s)',

    /** Change Item Supplier Return Dialog */
    changeItemSupplierReturnDescription:
      'This action changes VLG and Hybrid (post published) items status to Supplier Return. Do you really want to change it?',
    changeItemSupplierReturnWarningMessageTitle:
      'Items with different business models will not change status',
    changeStatus: 'Change Status',
    submitItemSupplierReturnError: 'There was an error submitting items for supplier return',
    validateItemSupplierReturnError: 'There was an error validating items for supplier return',

    /** DataGrid Headers */
    costWithTax: 'Cost With Tax',
    status: 'Status',

    /** Row Highlighting Tooltips */
    newProduct: 'New Product',
    processingPriorityItems: 'Processing priority items',

    /** BSP & CP Mass Update */
    bspDownloadTemplateError: 'There was an error downloading template file.',
    bspMassUpdateErrorMessageTitle: 'Errors were found- Please download the newest version',
    bspMassUpdateSuccess:
      "Items changed BSP and/or CP's successfully. These changes may take some time.",
    bspMassUpdateSubmissionError: 'There was an error updating the items!',
    bspMassUpdateValidationError: 'There was an error validating the items!',
    bspMassUpdateWarningMessageTitle:
      'This action is only applicable to items in "Published" (Available, Reserved and Consigned status). The SKU listed below will remain unchanged.',
    downloadBSPandCP: "BSP/CP's Template Download",
    uploadBSPandCP: "BSP/CP's Upload",
  },
  itemPhotos: {
    delete: 'Delete',
    deleteEditedPhoto: 'Delete Edited Photo',
    deleteEditedPhotoDescription:
      'This action will delete an Edited Photo. Are you sure you want to delete an Edited Photo?',
    download: 'Download',
    downloadAll: 'Download All',
    dndHint: 'Tap the image to drag and drop.',
    errorBulkDownloadPhotos: 'There was an error downloading the photos!',
    errorDeletePhoto: 'There was an error deleting the item photo!',
    errorDownloadPhoto: 'There was an error downloading the item photo!',
    errorReorderPhotos: 'There was an error reordering the item photos!',
    noPhotosYet: 'No photos yet',
    photo: 'Photo',
    successDeleteMessage: 'Photo deleted successfully.',

    /**  */
    editedPhotos: 'Edited Squared Photos - 2000px x 2000px',
    farfetchPhotos: 'Edited Rectangle Photos - 2048px x 2732px',
  },
  itemPricing: {
    pricing: 'Pricing',
    submitPricing: 'Submit Pricing',
    submitPricingSendToReview: 'Item %SKU% sent back to Review.',
    submitPricingSuccess: 'Item %SKU% submitted pricing successfully.',
    submitPricingReturnToSupplier: 'Item %SKU% returned to supplier.',

    /** BSP */
    bsp: 'BSP',

    /** Errors */
    errorGetKatalogInfo: 'There was an error getting the Katalog information!',
    errorGetPricingDetails: 'There was an error getting the pricing details!',
    errorGetReturnSupplierReasons: 'There was an error getting the return to supplier reasons!',
    errorSubmitPricing: 'There was an error submitting the pricing!',

    /** Item Cost */
    itemCost: 'Item Cost',

    /** Prices */
    prices: 'Prices',

    /** Send To Review */
    sendItemBackToReview: 'Send item back to Review',
    withIssues: 'With Issues',

    /** Supplier Return */
    sendItemToSupplier: 'Send item to Supplier return',
    supplierReturn: 'Supplier Return',
  },
  itemReview: {
    qcSuggestion: 'Quality check suggestion',
    ratingSuggestion: 'Rating suggestion',
    submitReview: 'Submit Review',

    /** Errors */
    errorGetOptions: 'There was an error getting the review details options!',
    errorGetReviewDetails: 'There was an error getting the review details!',
    errorGetItemIssueReasons: 'There was an error getting the item issue reasons!',
    errorGetSuggestionQC: 'There was an error getting the quality check suggestion!',
    errorGetSuggestionRating: 'There was an error getting the rating suggestion!',

    /** Damage Recognition  */
    damage: {
      cigarette: 'Cigarette',
      cracked: 'Cracked',
      creased: 'Creased',
      cutStitching: 'Cut Stitching',
      damaged: 'Damaged',
      discolored: 'Discolored',
      looseStitching: 'Loose Stitching',
      missing: 'Missing',
      mold: 'Mold',
      musty: 'Musty',
      others: 'Others',
      outOfShape: 'Out of Shape',
      peeling: 'Peeling',
      penMark: 'Pen Mark',
      perfume: 'Perfume',
      personalized: 'Personalized',
      repaired: 'Repaired',
      ripped: 'Ripped',
      rustyOrTarnished: 'Rusty/Tarnished',
      scratched: 'Scratched',
      split: 'Split',
      sticky: 'Sticky',
      transferOfColor: 'Transfer of Color',
      waterMark: 'Water Mark',
      worn: 'Worn',
    },
    damageCategory: {
      damages: 'Damages',
      general: 'General',
      otherDamages: 'Other Damages',
      parts: 'Parts',
      stained: 'Stained',
    },
    damageLocation: {
      back: 'Back',
      bottom: 'Bottom',
      closure: 'Closure',
      corners: 'Corners',
      embellishment: 'Embellishment',
      front: 'Front',
      handleOrStrap: 'Handle/Strap',
      interiorPocket: 'Pocket',
      key: 'Key',
      lining: 'Lining',
      pocket: 'Pocket',
      practicalAttachment: 'Practical Attachment',
      side: 'Side',
      smell: 'Smell',
      top: 'Top',
      zipper: 'Zipper',
    },
    damageSeverity: {
      none: 'None',
      low: 'Low',
      medium: 'Medium',
      high: 'High',
    },
    errorDamages: 'Identify one or more damages',
    exterior: 'Exterior',
    hardware: 'Hardware',
    identifyDamages: 'Identify Damages',
    interior: 'Interior',
    itemWithDamages: 'Item With Damages',
    itemWithoutDamages: 'Item Without Damages',
    submitDamages: 'Submit Damages',
    submitDamagesError: 'There was an error submiting the damages!',
    submitDamagesSuccess: 'Item Damages submitted successfully.',

    /** Item Check-up */
    itemCheckUp: 'Item Check-up',
    itemIssueTypes: {
      authentication: 'Authentication',
      checkItemInfo: 'Check Item Info',
      photography: 'Photography',
      qualityControl: 'Quality Control',
      supplierReturn: 'Supplier Return',
      photoEditing: 'Photo Editing', // TODO: Remove together with feature flag.
    },
    itemIssueStatuses: {
      toDo: 'To Do',
      inProgress: 'In Progress',
      resolved: 'Resolved',
      sentToWarehouse: 'Sent to Warehouse',
      submittedFromWarehouse: 'Submitted from Warehouse',
    },
    itemOk: 'Ok',
    itemReturnToSupplier: 'Not Ok - Return to the Supplier',
    itemReturnToSupplierDeadline:
      'The deadline for returning to the supplier has passed in %DAYS% days (holidays not considered).',
    itemReview: 'Item Review',
    itemSendToBBox: 'Not Ok - Send to BBox',
    previousIssue: 'Previous issue',

    /** Item Issue History */
    changedStatus: 'Changed Status',
    createdBy: 'By: %CREATED_BY%',
    history: 'History',
    getItemIssueDetailsError: 'There was an error getting item issue details!',

    /** Ratings Info */
    ratingUnworn: 'Unworn',
    ratingUnwornDescription:
      'The item is brand new and has never been used. It is free of any damage and includes all original inclusions, such as the box, dust bag, and authenticity card (if applicable).',
    ratingPristine: 'Pristine',
    ratingPristineDescription:
      'The item has no signs of use. It is free of any damage and may come with incomplete inclusions or no additional inclusions.',
    ratingExcellent: 'Excellent',
    ratingExcellentDescription:
      'The item has been used and very well taken care of. There are sign/s of minor wear that will be described in detail and visually declared in product photos.',
    ratingGood: 'Good',
    ratingGoodDescription:
      'The item has been well-used and displays significant and multiple signs of wear. All imperfections are described in detail and/or shown in the product photos.',
    ratingFair: 'Fair',
    ratingFairDescription:
      'The item has been well used and has significant and multiple signs of wear. All imperfections will be described in detail and/or declared.',
    ratingFlawed: 'Flawed',
    ratingFlawedDescription:
      'The item is in poor condition and show significant signs of wear and tear. It may not be suitable for everyday use. Individuals who are interested in restoring vintage handbags may wish to purchase such items for salvage or archival purposes.',

    /** Submit Review */
    submitError: 'There was an error submitting the review!',
    submitReviewSuccess: 'Item %SKU% Review completed successfully',
    submitReviewSendToProductMatch: 'Item %SKU% sent back to Product Match',

    /** Send To Product Match */
    inputIssues: 'Input Issues',
    openPreviousInputIssues: 'Open Previous Input Issues',
    previousInputIssues: 'Previous Input Issues',
    sendItemBackToProductMatch: 'Send item back to Product Match',
    toBeRectified: 'To be rectified',
    toBeRectifiedInReProductMatch: 'To be Rectified in Re-product Match',
    withIssues: 'With Issues',
  },
  qualityCheck: {
    authentication: 'Authentication',
    authenticationFail: 'Auth fail',
    authenticationPass: 'Auth pass',
    certificateLink: 'Certificate Link',
    commentsFromSupplier: 'Comments from Supplier',
    commentsFromSupplierPlaceholder: 'Damage comments were not provided by the supplier.',
    noCertificate: 'No certificate',
    parts: 'Parts',
    qcDamagesDescription: 'QC Damages Description',
    qcTrackLabel: 'Initially a QC Fail',
    qualityCheckDecision: 'Quality Check Decision',
    qualityCheckFail: 'QC fail',
    qualityCheckPass: 'QC pass',

    /** Errors */
    errorGetDetails: 'There was an error getting the item quality details!',

    /** Damage Recognition */
    location: 'Location',
  },
  statusRevamp: {
    reProductMatch: 'Re-PM (%COUNT%)',
    productMatchPriority: 'Priority (%COUNT%)',
    newProduct: 'New Product (%COUNT%)',
    nonPriority: 'Non Priority (%COUNT%)',
  },
  channelTab: {
    emptyStateTitle: 'No information available',
    emptyStateLabel: 'The item has not been published yet or doesn’t have a history.',
    clientsTitle: 'Clients',
    clientsSub: 'out of',
    clientsSubCont: 'clients',
    clientsReserved: 'This item has been reserved',
    clientsConsigned: 'This item has been consigned for',
    clientsSold: 'This item was sold on',
    clientsDays: 'days',
    allocationTitle: 'Allocation channels',
    allocationApi: 'API',
    clients: 'Clients',
    allocationStore: 'Store',
    allocationSold: 'The item is currently sold, no active allocation.',
    republishingTitle: 'Republishing',
    republishingSub: 'Active',
    highValueItem: 'High value item',
    activeRepublishing: 'Republishing',
    characteristicsNone: 'No relevant information for now',
    ageTitle: 'Age',
    ageTooltipTitle: 'Age cohort (published)',
    ageTooltip: [
      { name: 'Healthy', days: '0 - 90 days' },
      { name: 'Aging', days: '91 - 180 days' },
      { name: 'Old', days: '181 - 365 days' },
      { name: 'Obsolete', days: '> 366 days' },
    ],
    agePublished: 'Published',
    agePublishedTooltip:
      'Indicates how long the item has been available for sale since it was first published (the time between its initial publication date and the current date).',
    ageAvailable: 'Available',
    ageAvailableTooltip:
      'This age reflects the actual time the item has been available to float clients, excluding periods when it was temporarily unavailable due to reservations or consignments (Published Age minus the number of days the item was reserved or consigned).',
    ageSub: ' Days',
    offersTitle: 'Offers',
    activeOffers: 'Closed offers',
    totalOffers: 'This item has been offered',
    totalOffersCont: 'times',
    priceActionsTitle: 'Price Actions',
    fixed: 'Fixed',
    fixedLabel: 'A permanent adjustment made to the price.',
    temporary: 'Temporary',
    temporaryLabel: 'A brief price movement within a specific duration.',
    closedPriceActions: 'Closed price actions',
    ongoingPriceActions: 'Ongoing price actions',
    note: 'Note: Family & Friends are excluded from this information',
  },
};

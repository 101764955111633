import { createContext, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ApiException,
  EEditStatus,
  EItemInventoryStatus,
  EItemStatus,
  GetProductByIdQueryResponse,
  GetProductReviewQueryResponse,
  GetQualityDetailsQueryResponse,
  ProductsPhotosGetModel,
} from '@inbound/api';
import { UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ReactFCC } from 'types/react';
import { ISelectOptionIdName, ISelectOptionKeyValue } from '@lux-ds/dropdown';
import { useNotification } from '@lux-ds/notification';
import SimpleDialog, { ISimpleDialogProps } from '@@components/simple-dialog/SimpleDialog';
import useApi from '@@hooks/useApi';
import useAuth from '@@hooks/useAuth';
import useContent from '@@hooks/useContent';
import EPermission from '@@setup/constants/EPermission';
import ERole from '@@setup/constants/ERole';
import EFormId from '../constants/EFormId';
import EItemDetailsTab from '../constants/EItemDetailsTab';
import {
  IItemDetailsSchema,
  IPricingInfoSchema,
  IProductDetailsSchema,
  IProductMatchSchema,
} from '../schemas/TabItemDetailsSchema';

interface IDialogState extends Omit<ISimpleDialogProps, 'onClose'> {}

export interface IRefEditForm {
  discardChanges: () => void;
  form:
    | UseFormReturn<IItemDetailsSchema>
    | UseFormReturn<IPricingInfoSchema>
    | UseFormReturn<IProductDetailsSchema>
    | UseFormReturn<IProductMatchSchema>;
  hasChanges: boolean;
  id: EFormId;
  submit: (onSaveChanges?: () => void) => void;
}

interface IInputIssueDetails {
  inputIssues: GetProductReviewQueryResponse['attributeToBeRectifieds'];
  inputIssuesComment: GetProductReviewQueryResponse['attributesToBeRectifiedComment'];
}

interface IItemDetailsContext {
  id?: string;
  checkFormChange: (formRef: RefObject<IRefEditForm> | null, onSaveChanges?: () => void) => void;
  checkUnsavedChanges: (
    dialogProps: IDialogState,
    onUnchanged?: () => void,
    isFormDirty?: boolean,
  ) => void;
  editMode: EEditStatus | null;
  getItemPhotos: () => void;
  getQualityDetails: () => void;
  getSuggestedRRP: (productId: string) => Promise<void>;
  hasItemIssues: boolean;
  hasSidebarOpen: boolean;
  headerTag: string;
  hideSidePanel: boolean;
  inputIssueDetails?: IInputIssueDetails;
  isItemReviewWithChanges: boolean;
  isLoadedPhotos: boolean;
  isNewCategoryNotBags: boolean;
  isSaving: boolean;
  isSubmitting: boolean;
  isSuggestedProductInvalid: boolean;
  isWideView: boolean;
  itemDetails: GetProductByIdQueryResponse;
  itemPhotos: ProductsPhotosGetModel[];
  itemQualityDetails: GetQualityDetailsQueryResponse;
  loadingDetails: boolean;
  loadingPhotos: boolean;
  loadingQualityDetails: boolean;
  loadingRatingOptions: boolean;
  newCategory: ISelectOptionIdName[];
  ratingOptions: ISelectOptionKeyValue[];
  refEditForm: RefObject<IRefEditForm> | null;
  setHeaderTag: (headerTag: string) => void;
  setHideSidePanel: (hideSidePanel: boolean) => void;
  setIsItemReviewWithChanges: (isItemReviewWithChanges: boolean) => void;
  setIsSaving: (isSaving: boolean) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setIsSuggestedProductInvalid: (isSuggestedProductInvalid: boolean) => void;
  setIsWideView: (isWideView: boolean) => void;
  setItemDetails: (itemDetails: GetProductByIdQueryResponse) => void;
  setItemPhotos: (photos: ProductsPhotosGetModel[]) => void;
  setNewCategory: (newCategory: ISelectOptionIdName[]) => void;
  setRefEditForm: (ref: RefObject<IRefEditForm> | null) => void;
  setShouldShowField: (shouldShowField: boolean | undefined) => void;
  setStartTime: (startTime: number) => void;
  setTab: (tab: EItemDetailsTab) => void;
  shouldShowField: boolean | undefined;
  showMatchSidebar: boolean | undefined;
  startTime: number | undefined;
  suggestedRRP: number | undefined;
  tab: EItemDetailsTab;
}

const ItemDetailsContext = createContext<IItemDetailsContext>({} as IItemDetailsContext);

const ItemDetailsProvider: ReactFCC = ({ children }) => {
  const api = useApi();
  const {
    content: { common, itemDetails: itemDetailsContent, qualityCheck: qualityCheckContent },
  } = useContent();
  const { enqueueNotification } = useNotification();
  const { userPermissions, userRoles } = useAuth();
  const { id } = useParams() as { id: string };

  const [editMode, setEditMode] = useState<IItemDetailsContext['editMode']>(null);
  const [hasItemIssues, setHasItemIssues] = useState<IItemDetailsContext['hasItemIssues']>(false);
  const [headerTag, setHeaderTag] = useState<IItemDetailsContext['headerTag']>('');
  const [hideSidePanel, setHideSidePanel] = useState<IItemDetailsContext['hideSidePanel']>(false);
  const [inputIssueDetails, setInputIssueDetails] =
    useState<IItemDetailsContext['inputIssueDetails']>();
  const [isItemReviewWithChanges, setIsItemReviewWithChanges] =
    useState<IItemDetailsContext['isItemReviewWithChanges']>(false);
  const [isLoadedPhotos, setIsLoadedPhotos] =
    useState<IItemDetailsContext['isLoadedPhotos']>(false);
  const [isSaving, setIsSaving] = useState<IItemDetailsContext['isSaving']>(false);
  const [isSubmitting, setIsSubmitting] = useState<IItemDetailsContext['isSubmitting']>(false);
  const [isWideView, setIsWideView] = useState<IItemDetailsContext['isWideView']>(true);
  const [itemDetails, setItemDetails] = useState<IItemDetailsContext['itemDetails']>({});
  const [itemPhotos, setItemPhotos] = useState<IItemDetailsContext['itemPhotos']>([]);
  const [itemQualityDetails, setItemQualityDetails] = useState<
    IItemDetailsContext['itemQualityDetails']
  >({});
  const [loadingDetails, setLoadingDetails] = useState<IItemDetailsContext['loadingDetails']>(true);
  const [loadingPhotos, setLoadingPhotos] = useState<IItemDetailsContext['loadingPhotos']>(true);
  const [loadingQualityDetails, setLoadingQualityDetails] =
    useState<IItemDetailsContext['loadingQualityDetails']>(true);
  const [loadingRatingOptions, setLoadingRatingOptions] =
    useState<IItemDetailsContext['loadingRatingOptions']>(true);
  const [newCategory, setNewCategory] = useState<IItemDetailsContext['newCategory']>([]);
  const [ratingOptions, setRatingOptions] = useState<IItemDetailsContext['ratingOptions']>([]);
  const [refEditForm, setRefEditForm] = useState<RefObject<IRefEditForm> | null>(null);
  const [tab, setTab] = useState<IItemDetailsContext['tab']>(EItemDetailsTab.ItemDetails);
  const [unsavedChangesDialog, setUnsavedChangesDialog] = useState<IDialogState | undefined>(
    undefined,
  );

  const [isSuggestedProductInvalid, setIsSuggestedProductInvalid] =
    useState<IItemDetailsContext['isSuggestedProductInvalid']>(false);
  const [suggestedRRP, setSuggestedRRP] = useState<IItemDetailsContext['suggestedRRP']>();

  const [shouldShowField, setShouldShowField] =
    useState<IItemDetailsContext['shouldShowField']>(false);

  const [startTime, setStartTime] = useState<IItemDetailsContext['startTime']>();

  const isNewCategoryNotBags =
    newCategory.length > 0 && newCategory[0].name !== 'Bags' ? true : false;

  useEffect(() => {
    if (itemDetails.isCategoryCatalogCompatible || itemDetails.category?.name === 'Bags') {
      setShouldShowField(true);
    }
  }, [itemDetails.category?.name, itemDetails.isCategoryCatalogCompatible, setShouldShowField]);

  const handleSetItemDetails = useCallback(
    (itemDetails: GetProductByIdQueryResponse) =>
      setItemDetails(prevState => ({ ...prevState, ...itemDetails })),
    [],
  );

  const handleSetHeaderTag = useCallback((headerTag: string) => setHeaderTag(headerTag), []);

  const handleSetItemPhotos = useCallback(
    (itemPhotos: ProductsPhotosGetModel[]) => setItemPhotos(itemPhotos),
    [],
  );

  const handleSetIsSaving = useCallback((isSaving: boolean) => setIsSaving(isSaving), []);

  const handleSetIsSubmitting = useCallback(
    (isSubmitting: boolean) => setIsSubmitting(isSubmitting),
    [],
  );

  const handleSetIsWideView = useCallback((isWideView: boolean) => setIsWideView(isWideView), []);

  const handleSetRefEditForm = useCallback(
    (ref: RefObject<IRefEditForm> | null) => setRefEditForm(ref),
    [],
  );

  const handleSetTab = useCallback((tab: EItemDetailsTab) => setTab(tab), []);

  const getRatingOptions = useCallback(() => {
    const getRatingOptionsCallback = async () => {
      try {
        setLoadingRatingOptions(true);

        const newRatingOptions = await api.rating_GetRatings();

        setRatingOptions(newRatingOptions as ISelectOptionKeyValue[]);

        setLoadingRatingOptions(false);
      } catch {
        enqueueNotification({ title: itemDetailsContent.errorGetRatings }, { variant: 'error' });
      }
    };

    getRatingOptionsCallback();
  }, [api, enqueueNotification, itemDetailsContent]);

  useEffect(() => {
    if (editMode) {
      getRatingOptions();
    }
  }, [editMode, getRatingOptions]);

  const getSuggestedRRP = useCallback(
    async (productId: string) => {
      const getSuggestedRRPCallback = async () => {
        try {
          const { suggestedRRP } = await api.pricing_GetRRPSuggestionByProductId(productId);
          setSuggestedRRP(suggestedRRP);
        } catch (e: unknown) {
          const { status } = e as ApiException;

          if (status !== 404) {
            enqueueNotification(
              { title: 'There was an error getting the suggested RRP!' },
              { variant: 'error' },
            );
          }

          setSuggestedRRP(undefined);
        }
      };

      return getSuggestedRRPCallback();
    },
    [api, enqueueNotification],
  );

  useEffect(() => {
    if (
      itemDetails.editStatus === EEditStatus.ProductMatch ||
      itemDetails.editStatus === EEditStatus.Review
    ) {
      if (!!refEditForm?.current?.form && !!refEditForm?.current?.form.watch('catalogId')) {
        getSuggestedRRP(refEditForm?.current?.form.watch('catalogId') as unknown as string);
      } else if (!refEditForm?.current?.form && !!itemDetails.catalogId) {
        getSuggestedRRP(itemDetails.catalogId);
      } else {
        setSuggestedRRP(undefined);
      }
    }
  }, [itemDetails, refEditForm?.current?.form, refEditForm?.current?.form.watch('catalogId')]);

  const getItemDetails = useCallback(() => {
    const getItemDetailsCallback = async () => {
      try {
        setLoadingDetails(true);

        const newItemDetails = await api.products_GetByIdObsolete(id);

        setItemDetails(newItemDetails);

        if (
          (newItemDetails.status?.id !== EItemStatus.Canceled &&
            userPermissions.includes(EPermission.EditItem) &&
            newItemDetails.editStatus) ||
          (newItemDetails.editStatus === EEditStatus.AfterPricing &&
            userRoles.includes(ERole.InventorySenior))
        ) {
          setEditMode(newItemDetails.editStatus);
        }

        if (newItemDetails.isReProductMatch) {
          setHeaderTag(common.reproductMatch);

          const reviewDetailsResponse = await api.review_Get(id);
          if (reviewDetailsResponse.attributeToBeRectifieds?.length) {
            const newInputIssueDetails: IInputIssueDetails = {
              inputIssues: reviewDetailsResponse.attributeToBeRectifieds,
              inputIssuesComment: reviewDetailsResponse.attributesToBeRectifiedComment,
            };
            setInputIssueDetails(newInputIssueDetails);
          }
        }

        if (process.env.REACT_APP_ENABLE_EDIT_PHOTOS_WITH_ISSUES === 'true') {
          setHasItemIssues(!!newItemDetails.hasIssuesNotResolved);
        }

        if (
          (newItemDetails.status?.id === EItemInventoryStatus.Ordered ||
            newItemDetails.status?.id === EItemInventoryStatus.Draft) &&
          !!newItemDetails.suggestedCatalogId
        ) {
          getSuggestedRRP(newItemDetails.suggestedCatalogId);
        } else if (
          (newItemDetails.editStatus === EEditStatus.ProductMatch ||
            newItemDetails.editStatus === EEditStatus.Review) &&
          !!newItemDetails.catalogId
        ) {
          getSuggestedRRP(newItemDetails.catalogId);
        }
      } catch {
        enqueueNotification({ title: itemDetailsContent.errorGetItem }, { variant: 'error' });
      } finally {
        setLoadingDetails(false);
      }
    };

    getItemDetailsCallback();
  }, [
    api,
    common.reproductMatch,
    enqueueNotification,
    getSuggestedRRP,
    id,
    itemDetailsContent.errorGetItem,
    userPermissions,
    userRoles,
  ]);

  useEffect(() => {
    getItemDetails();
  }, [getItemDetails]);

  const getItemPhotos = useCallback(() => {
    const getItemPhotosCallback = async () => {
      try {
        const newItemPhotos = await api.photos_GetPhotosByItemId(id);

        setItemPhotos(newItemPhotos);
        setIsLoadedPhotos(true);
      } catch {
        enqueueNotification({ title: itemDetailsContent.errorGetPhotos }, { variant: 'error' });
      } finally {
        setLoadingPhotos(false);
      }
    };

    getItemPhotosCallback();
  }, [api, id, enqueueNotification, itemDetailsContent]);

  const handleUnsavedChangesClose = useCallback(() => setUnsavedChangesDialog(undefined), []);

  const handleUnsavedChangesOpen = useCallback(
    (dialogProps: IDialogState, onUnchanged?: () => void) => {
      if (refEditForm?.current?.hasChanges) {
        setUnsavedChangesDialog(dialogProps);
      } else if (onUnchanged) {
        onUnchanged();
      } else {
        dialogProps.action?.onClick();
      }
    },
    [refEditForm],
  );

  const getQualityDetails = useCallback(() => {
    const getQualityDetailsCallback = async () => {
      try {
        setLoadingQualityDetails(true);

        const qualityDetails = await api.products_GetQualityDetails(id as string);

        setItemQualityDetails(qualityDetails);
      } catch {
        enqueueNotification({ title: qualityCheckContent.errorGetDetails }, { variant: 'error' });
      } finally {
        setLoadingQualityDetails(false);
      }
    };

    getQualityDetailsCallback();
  }, [api, enqueueNotification, id, qualityCheckContent]);

  const handleCheckFormSubmit = useCallback(
    (onSaveChanges: () => void) => () => refEditForm?.current?.submit(onSaveChanges),
    [refEditForm],
  );

  const handleCheckFormChange = useCallback(
    (formRef: RefObject<IRefEditForm> | null, onSaveChanges?: () => void) => {
      const handleOnUnchanged = () => {
        setRefEditForm(formRef);
        onSaveChanges?.();
      };

      handleUnsavedChangesOpen(
        {
          action: {
            cancelLabel: common.cancel,
            label: itemDetailsContent.quitEditingSave,
            onClick: handleCheckFormSubmit(handleOnUnchanged),
          },
          children: itemDetailsContent.quitEditingDescription,
          title: itemDetailsContent.quitEditing,
        },
        handleOnUnchanged,
      );
    },
    [
      handleUnsavedChangesOpen,
      common.cancel,
      itemDetailsContent.quitEditingSave,
      itemDetailsContent.quitEditingDescription,
      itemDetailsContent.quitEditing,
      handleCheckFormSubmit,
    ],
  );

  const handleToggleSidePanel = useCallback(
    (hideSidePanel: boolean) => setHideSidePanel(hideSidePanel),
    [],
  );

  const showMatchSidebar = useMemo(
    () =>
      editMode === EEditStatus.Review
        ? isItemReviewWithChanges
        : (itemDetails.isCategoryCatalogCompatible || shouldShowField) &&
          (editMode === EEditStatus.ProductMatch ||
            (refEditForm?.current?.id === EFormId.ProductDetails &&
              editMode !== EEditStatus.BeforeMatch)),
    [
      editMode,
      isItemReviewWithChanges,
      itemDetails.isCategoryCatalogCompatible,
      refEditForm,
      shouldShowField,
    ],
  );

  const hasSidebarOpen = useMemo(() => {
    return (
      !hideSidePanel &&
      ((editMode === EEditStatus.Review && !isItemReviewWithChanges) ||
        editMode === EEditStatus.Pricing)
    );
  }, [editMode, hideSidePanel, isItemReviewWithChanges]);

  return (
    <ItemDetailsContext.Provider
      value={{
        checkFormChange: handleCheckFormChange,
        checkUnsavedChanges: handleUnsavedChangesOpen,
        editMode,
        getItemPhotos,
        getQualityDetails,
        getSuggestedRRP,
        hasItemIssues,
        hasSidebarOpen,
        headerTag,
        hideSidePanel,
        id,
        inputIssueDetails,
        isItemReviewWithChanges,
        isLoadedPhotos,
        isNewCategoryNotBags,
        isSaving,
        isSubmitting,
        isSuggestedProductInvalid,
        isWideView,
        itemDetails,
        itemPhotos,
        itemQualityDetails,
        loadingDetails,
        loadingPhotos,
        loadingQualityDetails,
        loadingRatingOptions,
        newCategory,
        ratingOptions,
        refEditForm,
        setHeaderTag: handleSetHeaderTag,
        setHideSidePanel: handleToggleSidePanel,
        setIsItemReviewWithChanges,
        setIsSaving: handleSetIsSaving,
        setIsSubmitting: handleSetIsSubmitting,
        setIsSuggestedProductInvalid,
        setIsWideView: handleSetIsWideView,
        setItemDetails: handleSetItemDetails,
        setItemPhotos: handleSetItemPhotos,
        setNewCategory,
        setRefEditForm: handleSetRefEditForm,
        setShouldShowField,
        setStartTime,
        setTab: handleSetTab,
        shouldShowField,
        showMatchSidebar,
        startTime,
        suggestedRRP,
        tab,
      }}
    >
      {children}
      {unsavedChangesDialog && (
        <SimpleDialog onClose={handleUnsavedChangesClose} {...unsavedChangesDialog} />
      )}
    </ItemDetailsContext.Provider>
  );
};

const ItemDetailsConsumer = ItemDetailsContext.Consumer;

export { ItemDetailsConsumer, ItemDetailsProvider };

export default ItemDetailsContext;

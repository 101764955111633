import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { AuthProvider } from '@@contexts/AuthContext';
import AppRouter from '@@setup/AppRouter';
import BaseConfiguration from '@@setup/BaseConfiguration';

const App: React.FC = () => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS || '', {
      gaOptions: {}, //TODO: add metrics if necessary
    });
  }, []);

  return (
    <BaseConfiguration>
      <AuthProvider>
        <AppRouter />
        {/* test commit */}
      </AuthProvider>
    </BaseConfiguration>
  );
};

export default App;

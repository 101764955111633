import { ReactNode, useCallback } from 'react';
import { LayoutMervin, Tooltip } from '@lux/ds';
import Close from '@mui/icons-material/Close';
import Button, { LoadingButton } from '@lux-ds/button';
import { ILayoutJayProps, LayoutHerodias, LayoutJay } from '@lux-ds/content-layouts';
import Dialog from '@lux-ds/dialog';
import { IDialogProps } from '@lux-ds/dialog';
import IconButton from '@lux-ds/icon-button';
import Message, { IMessageProps } from '@lux-ds/message';
import Typography from '@lux-ds/typography';
import useContent from '@@hooks/useContent';
import { StyledLayoutJay, VerifiedIcon } from './SimpleDialog.styles';
interface ISimpleDialogAction {
  cancelLabel?: string;
  disabled?: boolean;
  gap?: ILayoutJayProps['gap'];
  hideCancel?: boolean;
  isLoading?: boolean;
  label: string;
  onClick: () => void;
  shouldCloseDialog?: boolean;
}

export interface ISimpleDialogProps extends Omit<IDialogProps, 'open' | 'title'> {
  action?: ISimpleDialogAction;
  children: ReactNode;
  gap?: ILayoutJayProps['gap'];
  hideCloseIcon?: boolean;
  isCertified?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message?: IMessageProps | any;
  onClose: () => void;
  title: ReactNode;
}

const SimpleDialog: React.FC<ISimpleDialogProps> = ({
  action,
  children,
  gap = 's',
  hideCloseIcon,
  isCertified,
  message,
  onClose,
  title,
  ...props
}) => {
  const {
    content: { common, itemDetails },
  } = useContent();

  const handleAction = useCallback(() => {
    action?.onClick();

    if (action?.shouldCloseDialog !== false) {
      onClose();
    }
  }, [action, onClose]);

  return (
    <Dialog {...props} open onClose={onClose}>
      <StyledLayoutJay gap={action?.gap || 'l'} padding="m" scroll="hidden">
        <LayoutJay gap={gap} scroll="hidden">
          <LayoutHerodias sx={{ justifyContent: 'space-between' }}>
            <LayoutMervin>
              <Typography variant="h5" weight="semibold">
                {title}
              </Typography>
              {isCertified && (
                <Tooltip
                  placement="right"
                  title={common.certified}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -10],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <VerifiedIcon />
                </Tooltip>
              )}
            </LayoutMervin>
            {!hideCloseIcon ? (
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            ) : null}
          </LayoutHerodias>
          {message && (
            <Message title={message.title} description={message.description} type={message.type} />
          )}
          {typeof children === 'string' ? (
            <Typography sx={{ whiteSpace: 'pre', textWrap: 'wrap' }} variant="bodyTextNormal">
              {children}
            </Typography>
          ) : (
            children
          )}
        </LayoutJay>
        {action && (
          <LayoutHerodias align="right" gap="xs">
            {!action.hideCancel && (
              <Button onClick={onClose}>
                {action.cancelLabel || common.neverMind || itemDetails.goBack}
              </Button>
            )}
            <LoadingButton
              disabled={action.disabled}
              loading={action.isLoading}
              onClick={handleAction}
              variant="contained"
            >
              {action.label}
            </LoadingButton>
          </LayoutHerodias>
        )}
      </StyledLayoutJay>
    </Dialog>
  );
};

export default SimpleDialog;
